<template>
  <div>
    <div v-if="targetWorker && targetWorker._id" class="my-5">
      <v-row class="">
        <v-col cols="12" lg="auto" class="py-0">
          <v-avatar color="grey lighten-3" size="150" class="rounded-pill">
            <atoms-image v-if="targetWorker.photo" :width="100" lazy-src="/lazy-img.webp" :src="targetWorker.photo" />
            <v-icon v-else light>mdi-account</v-icon>
          </v-avatar>
        </v-col>
        <v-col cols="12" lg="auto" class="grow d-flex flex-column flex-lg-row justify-space-between align-center pa-0">
          <div :style="{ width: $vuetify.breakpoint.smAndDown ? '100%' : 'auto' }">
            <atoms-title>{{
              targetWorker.fullName ? $toCapitalize(targetWorker.fullName) : 'Tidak dicantumkan'
            }}</atoms-title>
            <atoms-text v-if="targetWorker.email" :href="`mailto:${targetWorker.email}`">{{
              targetWorker.email
            }}</atoms-text>
            <atoms-text v-else>-</atoms-text>
            <atoms-text v-if="targetWorker.detail && targetWorker.detail.prefJobs" :span="true">{{
              targetWorker.detail.prefJobs.filter((x) => x.length > 0).toString()
            }}</atoms-text>
            <br />
            <v-chip
              v-if="
                targetWorker.blacklist ||
                targetWorker.resigned ||
                (targetWorker.clientSuspends &&
                  targetWorker.clientSuspends.find((x) => x._id === $store.state.client._id))
              "
              class="rounded-lg red lighten-5 red--text ml-2 mt-2"
              >Status :
              {{
                (targetWorker.blacklist && 'Blacklisted') ||
                (targetWorker.resigned && 'Resign') ||
                (targetWorker.clientSuspends &&
                  targetWorker.clientSuspends.find((x) => x._id === $store.state.client._id) &&
                  'Suspend')
              }}</v-chip
            >
          </div>
          <div
            :class="['d-flex flex-column', ($vuetify.breakpoint.smAndDown && 'mt-5') || '']"
            :style="{ width: $vuetify.breakpoint.smAndDown ? '100%' : 'auto' }"
          >
            <atoms-button
              :disabled="!targetWorker.phone"
              target="_blank"
              :href="`https://wa.me/${getPhoneNumForWa(targetWorker.phone)}?text=Halo%2C`"
              full
              class="primary mt-2"
              >Hubungi Pelamar</atoms-button
            >
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-5">
        <v-col cols="12" class="px-0 elevation-0">
          <v-expansion-panels flat>
            <v-expansion-panel v-for="(item, i) in 1" :key="i">
              <v-expansion-panel-header @click="getHistory">
                Riwayat Pekerjaan Perusahaan Lainnya
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-if="historyWorkItems && historyWorkItems.length > 0 && !mainLoading">
                  <div v-for="({ client }, i) in historyWorkItems" :key="i" class="py-2 px-1">
                    <v-row align="start">
                      <v-col cols="10" class="text-start">
                        <atoms-text class="font-weight-bold">{{
                          (client && client.name) || 'Perusahaan ~'
                        }}</atoms-text>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-5"></v-divider>
                  </div>
                  <v-container class="max-width">
                    <v-pagination
                      class="my-4"
                      v-model="historyWorkPage"
                      :length="historyWork.pagesLength"
                    ></v-pagination>
                  </v-container>
                </div>
                <div v-else-if="mainLoading">
                  <atoms-text>Loading...</atoms-text>
                </div>
                <div v-else>
                  <atoms-text>Pekerja tidak memiliki riwayat bekerja</atoms-text>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="8" lg="10" class="pl-0">
          <atoms-text-field label="Lokasi" no-label>
            <v-autocomplete
              class="rounded-lg"
              :items="Object.values($contactStatus) || []"
              v-model="contactStatus"
              :menu-props="{ offsetY: true }"
              placeholder="Status Lamaran"
              hide-details
              solo
              flat
              dense
              required
              :style="{
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }"
            />
          </atoms-text-field>
        </v-col>
        <v-col cols="4" lg="2" class="pr-0">
          <atoms-button style="width: 100%" class="primary" @click="onSaveContactStatus">Simpan</atoms-button>
        </v-col>

        <v-col cols="12" class="accent rounded-lg pa-5 rounded-lg my-2 mt-10">
          <atoms-text class="font-weight-bold text-left primary--text">Alamat</atoms-text>
          <atoms-text class="text-left"
            ><span class="font-weight-bold">Provinsi: </span
            >{{
              targetWorker.address && targetWorker.address.province
                ? targetWorker.address.province
                : 'Tidak dicantumkan'
            }}</atoms-text
          >
          <atoms-text class="text-left"
            ><span class="font-weight-bold">Kota: </span
            >{{
              targetWorker.address && targetWorker.address.city ? targetWorker.address.city : 'Tidak dicantumkan'
            }}</atoms-text
          >
        </v-col>
        <v-col cols="12" class="accent rounded-lg pa-5 rounded-lg my-2">
          <atoms-text class="font-weight-bold text-left primary--text">Tentang Diri</atoms-text>
          <atoms-text class="text-left"
            ><span class="font-weight-bold">Deskripsi: </span
            >{{ targetWorker.about ? targetWorker.about : 'Tidak dicantumkan' }}</atoms-text
          >
          <atoms-text class="text-left" v-if="targetWorker.detail && targetWorker.detail.skills"
            ><span class="font-weight-bold">Kemampuan: </span>
            <span v-for="(x, i) in targetWorker.detail.skills" :key="i"
              ><br />-{{ x.name || '-' }}{{ x.duration || '-' }}
            </span>
          </atoms-text>
          <atoms-text class="text-left" v-if="targetWorker.detail && targetWorker.detail.experiences"
            ><span class="font-weight-bold">Pengalaman: </span>
            <span v-for="(x, i) in targetWorker.detail.experiences" :key="i"
              ><br />-{{ x.experience || 'Tidak dicantumkan' }} (<span class="primary--text">{{
                $moment(x.dateStart).format('DD-MM-YYYY')
              }}</span
              >-{{ $moment(x.dateEnd).format('DD-MM-YYYY') }})
            </span>
          </atoms-text>
        </v-col>

        <v-col v-if="targetWorker.detail" cols="12" class="text-left accent pa-5 rounded-lg my-2">
          <atoms-text class="font-weight-bold primary--text">Pengalaman Kerja</atoms-text>
          <div class="">
            <atoms-text
              ><span class="font-weight-bold">Pengalaman :</span>
              {{
                (targetWorker.detail.experiences &&
                  targetWorker.detail.experiences.filter((it) => it.length > 0).toString()) ||
                '-'
              }}</atoms-text
            >
            <atoms-text
              ><span class="font-weight-bold">Pekerjaan Sebelumnya :</span>
              {{
                (targetWorker.detail.prefJobs &&
                  targetWorker.detail.prefJobs.filter((it) => it.length > 0).toString()) ||
                '-'
              }}</atoms-text
            >
            <atoms-text
              ><span class="font-weight-bold">Referensi Kota :</span>
              {{
                (targetWorker.detail.prefCities &&
                  targetWorker.detail.prefCities.filter((it) => it.length > 0).toString()) ||
                '-'
              }}</atoms-text
            >
            <atoms-text
              ><span class="font-weight-bold">Kemampuan :</span>
              {{
                (targetWorker.detail.skills &&
                  targetWorker.detail.skills
                    .filter((it) => it.name)
                    .map((it) => `${it.name} ${it.duration} `)
                    .toString()) ||
                '-'
              }}</atoms-text
            >
          </div>
        </v-col>
        <v-col v-if="targetWorker.detail" cols="12" class="text-left accent pa-5 rounded-lg my-2">
          <atoms-text class="primary--text font-weight-bold">Detail Pekerja</atoms-text>
          <div class=" ">
            <div>
              <atoms-text class="font-weight-bold">Keterampilan :</atoms-text>
              <ul>
                <li v-for="(x, i) in targetWorker.detail.skills" :key="i">
                  {{ x.name || ':' }} Selama {{ x.duration || ':' }}
                </li>
              </ul>
            </div>
            <div>
              <atoms-text class="font-weight-bold">Pengalaman :</atoms-text>
              <ul>
                <li v-for="(x, i) in targetWorker.detail.experiences" :key="i">
                  {{ x.experience || ':' }} dari
                  <span class="primary--text">{{ $moment(x.dateStart && x.dateStart).format('DD-MM-YYYY') }}</span>
                  Sampai
                  <span class="green--text">
                    {{ $moment(x.dateEnd && x.dateEnd).format('DD-MM-YYYY') }}
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <atoms-text class="font-weight-bold">Preferensi Pekerjaan :</atoms-text>
              <ul>
                <li v-for="(x, i) in targetWorker.detail.prefJobs" :key="i">
                  {{ x }}
                </li>
              </ul>
            </div>
            <div>
              <atoms-text class="font-weight-bold">Preferensi Kota :</atoms-text>
              <ul>
                <li v-for="(x, i) in targetWorker.detail.prefCities" :key="i">
                  {{ x.city || ':' }}-{{ x.province || ':' }}
                </li>
              </ul>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <p v-else>Memuat data pekerja...</p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      url: '',
      contactStatus: null,
      targetWorker: null,
      targetVacancy: null,
      mainLoading: false,
      historyWorkPage: 1,
      historyWorkLimit: 10,
      historyWork: null,
      historyWorkItems: null,
    }
  },
  async mounted() {
    this.targetWorker = this.$route.query.targetWorker
    this.targetVacancy = this.$route.query.targetVacancy
    if (!this.targetWorker || !this.targetVacancy) {
      return this.$router.back()
    }

    if (this.targetWorker) {
      this.targetWorker = (
        await this.$store.dispatch('worker/getAll', {
          query: {
            _id: this.targetWorker,
          },
          returnOnly: true,
        })
      )?.result?.[0]
    }

    if (this.targetVacancy) {
      this.targetVacancy = (
        await this.$store.dispatch('vacancy/getAll', {
          query: {
            _id: this.targetVacancy,
          },
          returnOnly: true,
        })
      )?.result?.[0]
    }
    // console.log(this.targetWorker);
    const findContactStatus =
      this.targetWorker.contacted?.status &&
      Object.values(this.$contactStatus)?.find((it) => it === this.targetWorker.contacted?.status)
    if (findContactStatus) {
      this.contactStatus = findContactStatus
    } else if (this.targetWorker.contacted) {
      this.contactStatus = this.$contactStatus.Contacted
    } else {
      this.contactStatus = this.$contactStatus.Not_Contacted
    }
  },

  methods: {
    getPhoneNumForWa(payload) {
      payload = String(payload)
      if (payload?.includes('0')) {
        payload = '62' + payload?.substring(1)
      }
      return payload
    },
    getContacted(payload, workerId) {
      payload = payload?.find((it) => it.workerId === workerId)
      if (payload?.workerId) {
        return payload
      }
      return null
    },
    async onSaveContactStatus(payload) {
      this.$store.commit('SET_LOADING', true)
      try {
        const resp = await this.$store.dispatch('vacancy/setContactedStatus', {
          _id: this.targetWorker.vacancyId,
          workerId: this.targetWorker._id,
          status: this.contactStatus,
        })
        // console.log(resp);
      } finally {
        this.$store.commit('SET_LOADING', false)
      }
    },
    async getHistory() {
      this.mainLoading = true
      this.historyWork = await this.$api.get('QuotationItems/get', {
        params: {
          jsonQuery: JSON.stringify({
            workerIds: {
              $in: [this.targetWorker._id],
            },
            pipeline: [
              {
                $lookup: {
                  from: 'Clients',
                  localField: 'clientId',
                  foreignField: '_id',
                  as: 'client',
                },
              },
              {
                $lookup: {
                  from: 'Areas',
                  localField: 'areaId',
                  foreignField: '_id',
                  as: 'area',
                },
              },
            ],
          }),
          page: 1,
          limit: this.historyWorkLimit,
        },
      })

      this.historyWorkItems = this.historyWork?.result?.map((it) => {
        return {
          ...it,
          client: it.client?.[0],
        }
      })

      const filterDistinct = this.historyWorkItems
        ? new Set(this.historyWorkItems?.flatMap((it) => it.workerIds))
        : null

      this.historyWorkItems =
        (filterDistinct && [...filterDistinct.values()]?.filter((it) => it == this.targetWorker._id))?.map((it) => {
          const findClient = this.historyWorkItems?.find((x) => x.workerIds.includes(it))
          return findClient
        }) || null

      this.mainLoading = false
    },
  },
}
</script>
